/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://lcgi6wug2ban5px7sdebccwhwq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-t7jvr5jjdzavnctf35w4eybjfa",
    "aws_cloud_logic_custom": [
        {
            "name": "api504bcf73",
            "endpoint": "https://twermdd9bc.execute-api.us-east-2.amazonaws.com/staging",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_MwKP5RP3P",
    "aws_user_pools_web_client_id": "35u9ddrmicfk4i3gr1jfunq55n",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_user_files_s3_bucket": "amplifyappreactdce40d607b7842a9932fc62338d2046c213628-staging",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
